<template>
    <div class="item-container">
        <div v-if="isCredit" class="credit-label">Credit</div>
        <el-form ref="lineitem_form" :model="form">
            <div class="item-row">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="descriptionWidth">
                        <div class="row description">
                            <span class="el-input__inner input-value read-only">
                                {{ line_item.description }}
                            </span>
                        </div>
                    </el-col>

                    <!-- TOTAL INVOICEABLE -->
                    <el-col v-if="draft" :span="3">
                        <div class="row total">
                            <span class="el-input__inner input-value read-only">
                                {{ (total * sign) | currency }}
                            </span>
                        </div>
                    </el-col>

                    <!-- % THIS INVOICE -->
                    <el-col v-if="draft" :span="2">
                        <div class="row percent">
                            <el-input-number
                                v-if="total > 0"
                                ref="input_percent"
                                v-model="form.percent"
                                class="input-value"
                                :controls="false"
                                size="small"
                                placeholder="0"
                                :min="0"
                                :max="100"
                                :precision="0"
                                :step="5"
                                @change="validatePercent"
                                @focus="selectInput"
                            />
                            <span
                                v-else
                                class="el-input__inner input-value read-only"
                            >
                                -
                            </span>
                        </div>
                    </el-col>
                    <el-col v-if="plan_total" :span="2">
                        <div class="row plan_total" :class="planTotalClass">
                            {{ form.percent.toFixed() }}%
                        </div>
                    </el-col>

                    <!-- if total row: TOTAL OF LINE ITEM FOR PLAN -->
                    <el-col v-if="plan_total" :span="3">
                        <div class="row plan_total" :class="planTotalClass">
                            {{ planTotalDifference | currency }}
                        </div>
                    </el-col>
                    <!-- otherwise, AMOUNT -->
                    <el-col v-else :span="3">
                        <div class="row amount">
                            <span v-if="isCredit" class="credit-prefix">
                                -
                            </span>
                            <money
                                ref="input_amount"
                                v-model="form.amount"
                                v-bind="money"
                                class="el-input__inner"
                                :readonly="!draft || total === 0"
                                @blur.native="validateAmount"
                                @focus.native="selectInput"
                            />
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import {VMoney} from 'v-money';
import PaymentplanMixin from '@/mixins/paymentplan.mixin';
import {CURRENCY_TOL} from '@/utils/constants';

export default {
    name: 'paymentplan-invoice-line-item',
    directives: {money: VMoney},
    mixins: [PaymentplanMixin],
    props: {
        line_item: {
            type: Object,
            required: true,
        },
        draft: {
            type: Boolean,
            default: false,
        },
        plan_total: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                percent: 0,
                amount: 0,
                qty: 0,
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
        };
    },
    computed: {
        isCredit() {
            return this.line_item.credit;
        },
        sign() {
            return this.isCredit ? -1 : 1;
        },
        descriptionWidth() {
            // this is a draft invoice line
            if (this.draft) return 16;
            // this is a total line
            else if (this.line_item.plan_cost >= 0) return 19;
            else return 21;
        },
        total() {
            return this.discounted(this.line_item.plan_cost);
        },

        planTotalDifference() {
            return (
                (this.line_item.amount - this.line_item.plan_cost) *
                ((100 - (this.line_item.discount || 0)) / 100)
            );
        },
        planTotalClass() {
            if (this.planTotalDifference < -CURRENCY_TOL) {
                return 'low';
            } else if (this.planTotalDifference > CURRENCY_TOL) {
                return 'high';
            }
            return 'complete';
        },
    },
    watch: {
        line_item: {
            handler(item, old) {
                this.form.percent = item.plan_cost
                    ? (item.amount / item.plan_cost) * 100
                    : 100;
                this.form.amount = this.discounted(item.amount);
                this.form.qty = item.qty;
            },
            immediate: true,
        },
    },
    methods: {
        discounted(value) {
            return this.discountValue(value, this.line_item.discount);
        },
        undiscounted(value) {
            return this.undiscountValue(value, this.line_item.discount);
        },
        validateAmount() {
            // only recalculate amount if the discounted total is more than zero
            // (at the limit, we can't work backwards to get a percentage)
            if (this.draft && this.total > 0) {
                // check if input amount is within bounds
                if (this.form.amount < 0) this.form.amount = 0;
                if (this.form.amount > this.total)
                    this.form.amount = this.total;

                // recalculate percent
                this.form.percent = (this.form.amount / this.total) * 100;

                // recalculate qty
                this.form.qty =
                    this.undiscounted(this.form.amount) / this.line_item.rate;
                this.updateValues();
            }
        },
        validatePercent() {
            // check if input percent is within bounds
            if (this.form.percent < 0) this.form.percent = 0;
            if (this.form.percent > 100 - this.invoiced)
                this.form.amount = 100 - this.invoiced;

            // recalculate amount
            this.form.amount = (this.total * this.form.percent) / 100;
            // in case of rounding issues, cap invoice amount to remaining
            if (this.form.amount > this.line_item.total)
                this.form.amount = this.line_item.total;

            // recalculate qty
            this.form.qty =
                this.undiscounted(this.form.amount) / this.line_item.rate;

            this.updateValues();
        },
        updateValues() {
            this.$emit('update', {
                ...this.line_item,
                amount: this.undiscounted(this.form.amount),
                qty: this.form.qty,
            });
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    .item-row {
        padding: 2px 0 4px;

        .description {
            display: flex;
            align-items: center;

            .el-input__inner {
                justify-content: flex-start;
            }
        }

        .plan_total {
            font-size: 12px;
            font-weight: bold;
            &.high {
                color: red;
            }
            &.low {
                color: orange;
            }
            &.complete {
                color: green;
            }
        }
    }

    .row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ::v-deep .input-value {
        width: 100%;

        &.read-only,
        &.read-only input,
        input:read-only {
            background: transparent;
            color: #a0a0a0;
            pointer-events: none;
            border: none;
        }

        input {
            padding-left: 5px !important;
            padding-right: 5px !important;
            text-align: center;
        }
    }

    ::v-deep .el-input__inner {
        height: 32px;
        font-size: 13px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        font-family: Montserrat, sans-serif;
        &.read-only,
        &:read-only {
            border: 1px solid $border-grey !important;
            background: transparent;
        }
        .small {
            font-size: 11px;
        }
        &.bold {
            font-weight: bold;
        }
    }

    .credit-label {
        font-size: 11px;
        color: $black;
        margin: 10px 0 6px 0;
    }

    .credit-prefix {
        position: absolute;
        left: 10px;
    }
}
</style>
