<template>
    <div class="paymentplan-invoice-form">
        <div class="paymentplan-invoice-form-header">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col v-if="!invoiced" :span="2" class="left">
                    Scheduled
                </el-col>

                <el-col :span="ref_width" class="reference">
                    Reference
                    <el-tooltip
                        v-if="!this.is_scheduled && !this.invoiced"
                        content="Dates in reference for unscheduled invoices are
                        displayed using today's date."
                        placement="right"
                    >
                        <i class="el-icon-question" />
                    </el-tooltip>
                </el-col>

                <el-col :span="4" v-if="has_purchase_order">
                    Purchase order
                </el-col>

                <el-col v-if="is_scheduled || invoiced" :span="3">
                    <span v-if="invoiced">Invoice date</span>
                    <span v-else>Scheduled date</span>
                </el-col>

                <el-col v-if="is_scheduled || invoiced" :span="3">
                    Due date
                </el-col>

                <el-col v-if="!invoiced" :span="7">
                    Due date terms
                </el-col>
            </el-row>
        </div>

        <div class="paymentplan-invoice-form-row">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col v-if="!invoiced" :span="2" class="left">
                    <el-switch
                        v-model="is_scheduled"
                        style="margin-left: 10px;"
                    />
                </el-col>

                <el-col :span="ref_width" class="reference end-field">
                    <el-input
                        v-show="editingRef && !invoiced"
                        ref="referenceField"
                        v-model="form.reference_template"
                        class="input-value left"
                        @blur="
                            () => {
                                editReference(false);
                            }
                        "
                    />
                    <div
                        v-show="!editingRef || invoiced"
                        class="el-input__inner input-value left preview editable"
                        :class="{disabled: invoiced}"
                        @click="
                            () => {
                                editReference(true);
                            }
                        "
                    >
                        {{ reference_preview }}
                        <i v-if="!invoiced" class="edit-icon el-icon-edit" />
                    </div>
                </el-col>

                <el-col :span="4" v-if="has_purchase_order">
                    <div
                        class="el-input__inner input-value left disabled block"
                    >
                        {{ payment_plan.purchase_order }}
                    </div>
                </el-col>

                <el-col
                    v-if="is_scheduled || invoiced"
                    :span="3"
                    class="center end-field"
                >
                    <el-date-picker
                        v-model="form.schedule_date"
                        class="input-value"
                        :class="{invalid: form.schedule_date === null}"
                        :clearable="false"
                        format="dd/MM/yyyy"
                        :disabled="invoiced"
                        @change="handleRecalculateDueDate"
                    />
                </el-col>

                <el-col
                    v-if="is_scheduled || invoiced"
                    :span="3"
                    class="center end-field"
                >
                    <el-date-picker
                        v-model="form.due_date"
                        class="input-value"
                        format="dd/MM/yyyy"
                        :disabled="invoiced"
                        @change="handleDueDateChange"
                    />
                </el-col>

                <template v-if="!invoiced">
                    <el-col :span="2">
                        <el-input-number
                            v-model="form.due_value"
                            class="input-value"
                            :controls="false"
                            :min="1"
                            @change="handleRecalculateDueDate"
                        />
                    </el-col>
                    <el-col :span="5">
                        <el-select
                            v-model="form.due_terms"
                            @change="handleRecalculateDueDate"
                        >
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE"
                                label="days after invoice date"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_END_OF_MONTH
                                "
                                label="days after end of invoice month"
                            />
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH"
                                label="of the current month"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH
                                "
                                label="of the following month"
                            />
                        </el-select>
                    </el-col>
                </template>
            </el-row>
        </div>

        <div class="paymentplan-line-item-header">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="description_width" class="description">
                    Line item
                </el-col>

                <el-col v-if="draft" :span="3" class="center">
                    Total to Invoice
                </el-col>

                <el-col v-if="draft" :span="2" class="center">
                    % this invoice
                </el-col>

                <el-col :span="3" class="center">
                    Invoice amount
                </el-col>
            </el-row>
        </div>

        <div class="paymentplan-line-items">
            <payment-plan-invoice-line-item
                v-for="line_item in invoice.line_items"
                :key="line_item.id"
                :line_item="line_item"
                :draft="draft"
                @update="updateLineItem"
            />
        </div>

        <div class="paymentplan-line-item-footer">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="21" class="right">
                    Total
                </el-col>

                <el-col
                    :span="3"
                    class="center"
                    :class="{invalid: invoice_total < 0}"
                >
                    {{ invoice_total | currency }}
                </el-col>
            </el-row>
        </div>
        <reminder-list
            :reminders="form.reminders"
            @change="handleChangeReminders"
        />
    </div>
</template>

<script>
import PaymentPlanInvoiceLineItem from './PaymentPlan_invoice_line_item';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';
import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

import {PAYMENT_PLAN_DUE_TERMS} from '@/enums';
import dayjs from 'dayjs';

import ReminderList from '@/pages/paymentplans/components/Reminder_list.vue';

export default {
    name: 'paymentplan-invoice',
    components: {
        ReminderList,
        PaymentPlanInvoiceLineItem,
    },
    mixins: [ReferenceTemplateMixin, PaymentPlanMixin],
    props: {
        payment_plan: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        estimate: {
            type: Object,
            required: true,
        },
        draft: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            PAYMENT_PLAN_DUE_TERMS: PAYMENT_PLAN_DUE_TERMS,
            is_scheduled: true,
            form: {
                reference_template: '',
                schedule_date: null,
                due_date: null,
                due_value: 14,
                due_terms: PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
                reminders: [],
            },
            editingRef: false,
        };
    },
    computed: {
        ref_width() {
            let width = 5;
            if (!this.is_scheduled) width += 6;
            if (this.invoiced) width = 14;
            if (!this.has_purchase_order) width += 4;
            return width;
        },
        description_width() {
            return this.draft ? 16 : 21;
        },

        reference_preview() {
            return this.processTemplate(
                this.form.reference_template,
                this.is_scheduled
                    ? this.form.schedule_date ?? dayjs()
                    : dayjs(),
                this.invoice.sequence,
                this.payment_plan.quantity
            );
        },

        invoice_total() {
            return this.invoice.line_items.reduce((sum, item) => {
                const sign = item.credit ? -1 : 1;
                return (sum +=
                    item.amount * ((100 - (item.discount || 0)) / 100) * sign);
            }, 0);
        },
        invoiced() {
            return !!this.invoice.invoice_id;
        },
        has_purchase_order() {
            return !!this.payment_plan.purchase_order;
        },
    },
    watch: {
        invoice: {
            handler(invoice) {
                this.is_scheduled = invoice.is_scheduled;
                // use template if set, otherwise use reference (for legacy support)
                this.form.reference_template =
                    invoice.reference_template || invoice.reference;
                this.form.schedule_date = invoice.schedule_date;
                this.form.due_date = invoice.due_date;
                this.form.due_value = invoice.due_value;
                this.form.due_terms = invoice.due_terms;
                // workaround Vuefire loading nested references as strings >:(
                this.form.reminders = invoice.reminders
                    ? invoice.reminders.map((reminder) => {
                          return {
                              created_by: this.$fire.doc(
                                  `users/${this.$options.filters.fireRef2id(
                                      reminder.created_by
                                  )}`
                              ),
                              user: this.$fire.doc(
                                  `users/${this.$options.filters.fireRef2id(
                                      reminder.user
                                  )}`
                              ),
                              date: reminder.date,
                          };
                      })
                    : [];
            },
            immediate: true,
        },
        'payment_plan.reference'(ref) {
            this.form.reference_template = ref;
        },
        is_scheduled() {
            this.invoiceUpdated();
        },
        // checking all form values independently,
        // because a deep watch on the whole form object
        // would always trigger on the reminders array
        'form.reminders': {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    // reminders should only be added or removed, not modified,
                    // so only checking for change to array length here
                    if (newVal.length !== oldVal.length) {
                        // autosave after a change to reminders
                        this.invoiceUpdated(true);
                    }
                }
            },
        },
        'form.reference_template'() {
            this.invoiceUpdated();
        },
        'form.schedule_date'() {
            this.invoiceUpdated();
        },
        'form.due_date'() {
            this.invoiceUpdated();
        },
        'form.due_value'() {
            this.invoiceUpdated();
        },
        'form.due_terms'() {
            this.invoiceUpdated();
        },
    },
    methods: {
        updateLineItem(lineItem) {
            // replace existing invoice line item with new data
            const index = this.invoice.line_items.findIndex((li) => {
                return (
                    this.$options.filters.fireRef2id(li.estimate_group) ===
                    this.$options.filters.fireRef2id(lineItem.estimate_group)
                );
            });

            if (index >= 0) {
                this.invoice.line_items[index] = lineItem;
            }

            this.invoiceUpdated();
        },
        invoiceUpdated(autosave = false) {
            this.$emit(
                'update',
                {
                    ...this.invoice,
                    is_scheduled: this.is_scheduled,
                    reference_template: this.form.reference_template,
                    schedule_date: this.form.schedule_date,
                    due_date: this.form.due_date,
                    due_value: this.form.due_value,
                    due_terms: this.form.due_terms,
                    line_items: this.invoice.line_items,
                    reminders: this.form.reminders,
                },
                autosave
            );
        },
        handleRecalculateDueDate() {
            this.form.due_date = this.calculateDueDate(
                this.form.schedule_date,
                this.form.due_value,
                this.form.due_terms
            );
        },
        handleDueDateChange() {
            if (this.form.due_date === null) {
                setTimeout(this.handleRecalculateDueDate);
            }
        },
        editReference(editing = true) {
            this.editingRef = editing;
            if (editing) {
                setTimeout(this.$refs.referenceField.focus);
            }
        },
        handleChangeReminders(reminders) {
            this.form.reminders = reminders;
        },
    },
};
</script>

<style lang="scss" scoped>
.paymentplan-invoice-form {
    min-width: 810px;
    border-radius: 4px;
    padding: 10px 20px 20px 20px;
    border: 2px solid $border-grey-light;
    background-color: $grey;

    .paymentplan-invoice-form-header,
    .paymentplan-line-item-header {
        font-size: 11px;
        margin: 10px 0 6px 0;
    }

    .paymentplan-invoice-form-row {
        ::v-deep .input-value {
            width: 100%;

            &.preview {
                border: 1px solid $border-grey !important;
                font-size: 13px;

                &.editable {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    pointer-events: all;
                    cursor: pointer;

                    &.disabled {
                        cursor: default;
                    }
                }
            }

            input {
                padding-left: 5px !important;
                padding-right: 5px !important;
                text-align: center;
            }

            &.left input {
                text-align: left;
            }

            &.block {
                display: block;
            }
        }

        .el-select {
            width: 100%;
        }

        .edit-icon {
            line-height: 40px;
            font-size: 18px;
        }

        .save-icon {
            line-height: 40px;
            font-size: 18px;
            padding-right: 10px;
        }
    }

    .paymentplan-line-item-footer {
        font-size: 11px;
        font-weight: bold;
        margin: 6px 0;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .invalid {
        color: $red;
    }
}
</style>
