var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paymentplan-invoice-form" },
    [
      _c(
        "div",
        { staticClass: "paymentplan-invoice-form-header" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              !_vm.invoiced
                ? _c("el-col", { staticClass: "left", attrs: { span: 2 } }, [
                    _vm._v(" Scheduled "),
                  ])
                : _vm._e(),
              _c(
                "el-col",
                { staticClass: "reference", attrs: { span: _vm.ref_width } },
                [
                  _vm._v(" Reference "),
                  !this.is_scheduled && !this.invoiced
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "Dates in reference for unscheduled invoices are\n                    displayed using today's date.",
                            placement: "right",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.has_purchase_order
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v(" Purchase order "),
                  ])
                : _vm._e(),
              _vm.is_scheduled || _vm.invoiced
                ? _c("el-col", { attrs: { span: 3 } }, [
                    _vm.invoiced
                      ? _c("span", [_vm._v("Invoice date")])
                      : _c("span", [_vm._v("Scheduled date")]),
                  ])
                : _vm._e(),
              _vm.is_scheduled || _vm.invoiced
                ? _c("el-col", { attrs: { span: 3 } }, [_vm._v(" Due date ")])
                : _vm._e(),
              !_vm.invoiced
                ? _c("el-col", { attrs: { span: 7 } }, [
                    _vm._v(" Due date terms "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-invoice-form-row" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              !_vm.invoiced
                ? _c(
                    "el-col",
                    { staticClass: "left", attrs: { span: 2 } },
                    [
                      _c("el-switch", {
                        staticStyle: { "margin-left": "10px" },
                        model: {
                          value: _vm.is_scheduled,
                          callback: function ($$v) {
                            _vm.is_scheduled = $$v
                          },
                          expression: "is_scheduled",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                {
                  staticClass: "reference end-field",
                  attrs: { span: _vm.ref_width },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editingRef && !_vm.invoiced,
                        expression: "editingRef && !invoiced",
                      },
                    ],
                    ref: "referenceField",
                    staticClass: "input-value left",
                    on: {
                      blur: () => {
                        _vm.editReference(false)
                      },
                    },
                    model: {
                      value: _vm.form.reference_template,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reference_template", $$v)
                      },
                      expression: "form.reference_template",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editingRef || _vm.invoiced,
                          expression: "!editingRef || invoiced",
                        },
                      ],
                      staticClass:
                        "el-input__inner input-value left preview editable",
                      class: { disabled: _vm.invoiced },
                      on: {
                        click: () => {
                          _vm.editReference(true)
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.reference_preview) + " "),
                      !_vm.invoiced
                        ? _c("i", { staticClass: "edit-icon el-icon-edit" })
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm.has_purchase_order
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "el-input__inner input-value left disabled block",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.payment_plan.purchase_order) + " "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.is_scheduled || _vm.invoiced
                ? _c(
                    "el-col",
                    { staticClass: "center end-field", attrs: { span: 3 } },
                    [
                      _c("el-date-picker", {
                        staticClass: "input-value",
                        class: { invalid: _vm.form.schedule_date === null },
                        attrs: {
                          clearable: false,
                          format: "dd/MM/yyyy",
                          disabled: _vm.invoiced,
                        },
                        on: { change: _vm.handleRecalculateDueDate },
                        model: {
                          value: _vm.form.schedule_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "schedule_date", $$v)
                          },
                          expression: "form.schedule_date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.is_scheduled || _vm.invoiced
                ? _c(
                    "el-col",
                    { staticClass: "center end-field", attrs: { span: 3 } },
                    [
                      _c("el-date-picker", {
                        staticClass: "input-value",
                        attrs: { format: "dd/MM/yyyy", disabled: _vm.invoiced },
                        on: { change: _vm.handleDueDateChange },
                        model: {
                          value: _vm.form.due_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "due_date", $$v)
                          },
                          expression: "form.due_date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.invoiced
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c("el-input-number", {
                          staticClass: "input-value",
                          attrs: { controls: false, min: 1 },
                          on: { change: _vm.handleRecalculateDueDate },
                          model: {
                            value: _vm.form.due_value,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "due_value", $$v)
                            },
                            expression: "form.due_value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-select",
                          {
                            on: { change: _vm.handleRecalculateDueDate },
                            model: {
                              value: _vm.form.due_terms,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "due_terms", $$v)
                              },
                              expression: "form.due_terms",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                value:
                                  _vm.PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
                                label: "days after invoice date",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value:
                                  _vm.PAYMENT_PLAN_DUE_TERMS
                                    .DAYS_AFTER_END_OF_MONTH,
                                label: "days after end of invoice month",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: _vm.PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH,
                                label: "of the current month",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value:
                                  _vm.PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH,
                                label: "of the following month",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-line-item-header" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              _c(
                "el-col",
                {
                  staticClass: "description",
                  attrs: { span: _vm.description_width },
                },
                [_vm._v(" Line item ")]
              ),
              _vm.draft
                ? _c("el-col", { staticClass: "center", attrs: { span: 3 } }, [
                    _vm._v(" Total to Invoice "),
                  ])
                : _vm._e(),
              _vm.draft
                ? _c("el-col", { staticClass: "center", attrs: { span: 2 } }, [
                    _vm._v(" % this invoice "),
                  ])
                : _vm._e(),
              _c("el-col", { staticClass: "center", attrs: { span: 3 } }, [
                _vm._v(" Invoice amount "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-line-items" },
        _vm._l(_vm.invoice.line_items, function (line_item) {
          return _c("payment-plan-invoice-line-item", {
            key: line_item.id,
            attrs: { line_item: line_item, draft: _vm.draft },
            on: { update: _vm.updateLineItem },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-line-item-footer" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              _c("el-col", { staticClass: "right", attrs: { span: 21 } }, [
                _vm._v(" Total "),
              ]),
              _c(
                "el-col",
                {
                  staticClass: "center",
                  class: { invalid: _vm.invoice_total < 0 },
                  attrs: { span: 3 },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.invoice_total)) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("reminder-list", {
        attrs: { reminders: _vm.form.reminders },
        on: { change: _vm.handleChangeReminders },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }